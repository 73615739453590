.vessel-list-wrapper {
    height: calc(100%);

    .vessel-list-area-wrapper {
        width: 100%;
        height: calc(100%);
        margin: 0 auto;
        white-space: nowrap;
        overflow-y: hidden;

        .vessel-list-table {
            font-size: 13px;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            border-collapse: collapse;
            table-layout: fixed;
            display: block;

            thead tr,
            tbody tr {
                width: 100%;
                height: 50px;
                align-items: center;
            }

            thead {
                display: block;
                /* display: table; */
                width: 100%;

                th {
                    /* border: 1px #CBCBCB solid; */
                    font-family: Roboto, "Helvetica Neue", sans-serif;
                    font-weight: 500;
                    position: sticky;

                    span {
                        font-size: 13px;
                        color: rgb(255, 255, 255, 0.5);
                    }
                }

                tr:nth-of-type(2) {
                    height: 1px !important;
                    border-bottom: 1px dotted rgb(255, 255, 255, 0.5);
                }

                .favorite {
                    width: 3%;
                    text-align: left;
                }

                .vesselName {
                    width: 15%;
                    text-align: left;
                }

                .latestUpdate {
                    width: 10%;
                    text-align: left;
                }

                .padding1 {
                    width: 50px;
                }

                .CPCurve {
                    width: 200px;
                    padding-left: 12px;
                    text-align: left;
                }

                .ReferenceCurve {
                    width: 200px;
                    padding-left: 12px;
                    text-align: left;
                }

                .padding2 {
                    width: 100px;
                }

                .Last4Weeks {
                    width: 150px;
                    text-align: center;
                }

                .ThisYear {
                    width: 150px;
                    text-align: center;
                }

                .LastYear {
                    width: 150px;
                    text-align: center;

                }

                .padding3 {
                    width: 365px;
                }

            }

            tbody {
                display: block;
                overflow-y: scroll;
                height: 90%;
                width: 100%;

                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background: rgba(255, 255, 255, 0.5);
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #fff;
                    border-radius: 10px;
                }

                &::-webkit-scrollbar {
                    width: 8px;
                    height: 8px;
                }

                &::-webkit-scrollbar-corner {
                    display: none;
                }

                tr {

                    width: 100%;

                    &:nth-of-type(odd) {
                        background-color: rgba(255, 255, 255, 0.05);
                    }

                    &:hover {
                        background-color: rgba(255, 255, 255, 0.15);
                    }

                    td {
                        padding-bottom: 0px;
                        /* border: 1px #CBCBCB solid; */
                        font-family: Roboto;
                        font-weight: 400;
                        font-size: 15;
                        word-wrap: 'break-word';
                        vertical-align: middle;
                        white-space: nowrap;
                        height: 63px;
                        max-height: 63px;

                        span {
                            font-size: 13px;
                            color: rgb(255, 255, 255, 1);
                        }
                    }


                    .favorite {
                        width: 3%;
                        text-align: center;
                    }

                    .vesselName {
                        width: 15%;
                        text-align: left;
                    }

                    .latestUpdate {
                        width: 10%;
                        text-align: left;

                    }

                    .padding1 {
                        width: 50px;
                    }

                    .CPCurve {
                        width: 200px;
                        text-align: center;
                    }

                    .ReferenceCurve {
                        width: 200px;
                        text-align: center;
                    }

                    .padding2 {
                        width: 100px;
                    }

                    .Last4Weeks {
                        width: 150px;
                        text-align: center;
                    }

                    .ThisYear {
                        width: 150px;
                        text-align: center;
                    }

                    .LastYear {
                        width: 150px;
                        text-align: center;
                    }

                    .padding3 {
                        width: 365px;
                    }

                }

            }

        }


    }
}

/* 並び替え */

.common-sort-container {
    display: inline-block;
    padding-left: 4px;

    .common-sort-icon {
        display: flex;
        align-content: baseline;

        .common-sort-icon-ascending {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 5px 9.775px 5px;
            border-color: transparent transparent rgba(255, 255, 255, 0.5) transparent;
            cursor: pointer;

            &:hover {
                border-color: transparent transparent white transparent;
            }
        }

        .common-sort-icon-descending {
            width: 0;
            height: 0;
            margin-left: 1px;
            border-style: solid;
            border-width: 9.775px 5px 0 5px;
            border-color: rgba(255, 255, 255, 0.5) transparent transparent transparent;
            cursor: pointer;

            &:hover {
                border-color: white transparent transparent transparent;
            }
        }

        .sort-active-ascending {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 5px 9.775px 5px;
            border-color: transparent transparent white transparent;
            cursor: pointer;
        }

        .sort-active-descending {
            width: 0;
            height: 0;
            margin-left: 1px;
            border-style: solid;
            border-width: 9.775px 5px 0 5px;
            border-color: white transparent transparent transparent;
            cursor: pointer;
        }
    }
}