table {
    border-collapse:collapse;

    tbody {
        td {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 4px;
        }
    }
}
